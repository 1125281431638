//*****************************************************************************
//*****************************************************************************
//********************* CHANGES ***********************************************
//
//	#1 import statements and - as consequence - 
//     renaming of matter.js objects 
//
//  #2 remove matter.js block of mousewheel events
// 	   rescaling the 8 shapes with factor 1.4
//     change EXTRA_LENGTH for ring
//     change MIN_SPEED, MAX_ANGULAR_VELO, ROUSE_MILLISEC, REGAIN_MILLISEC 
//     	 
//*****************************************************************************
//*****************************************************************************

//*******************************************************
//			Imports
//******************************************************
/*
import * as PolyDecomp from "./poly-decomp.js";
import * as Matter from "./matter.js";
*/

import PolyDecomp from './poly-decomp.js';
import { Engine as _Engine, Render as _Render, Runner as _Runner, Composite as _Composite, Composites as _Composites, MouseConstraint as _MouseConstraint, Mouse as _Mouse, Common as _Common, Query as _Query, World as _World, Bodies as _Bodies, Body as _Body, Vertices as _Vertices, Svg as _Svg, Events as _Events } from './matter.js';


//*******************************************************
//
//			Shared Constants and Variables
//
//*******************************************************


//  settings
const MAX_SPEED = 3;
const MIN_SPEED = 2;
const MAX_ANGULAR_VELO = Math.PI / 12;
const WALL_MARGIN = 4;
const SHAPES_MARGIN = 4;
const CIRCLE_SIDES = 30;
const SLEEP_MILLISEC = 500;
const ROUSE_MILLISEC = 2000;
const REGAIN_MILLISEC = 2000;
const DESKTOP_MIN_WIDTH = 20000; // minimal screen width for desktops
const TABLET_MIN_WIDTH = 600;   // minimal screen width for desktops

// color codes
const LIGHT_GREEN ='#29ff42';  
const DARK_GREEN = '#215426'; 
const WHITE = '#ffffff';
const BLUE_MAGENTA = '#6666A0';
 

//************************************************************
// constants: shape attributes 
//   from SVG descriptors:  TYPE, COLOR, PATH OR POINTS
//   from hero.css and hero.js: LEFT (vw), TOP (vh), ROT (°)   
//   ... WIDTH, HEIGHT (px)  
//************************************************************  
const SVG_SHAPES = [ 
		{
		  NAME: "semiring",	
		  COLOR: LIGHT_GREEN,
		  LEFT: 16, TOP: 12, ROT: 160,
		  WIDTH: 170, HEIGHT: 75, RADIUS: 0,
		  POINTS: "239.58 0.00 239.48 4.99 239.17 9.98 238.66 14.95 237.94 19.91 237.01 24.82 235.88 29.69 234.54 34.50 233.00 39.26 231.27 43.94 229.33 48.57 227.20 53.09 224.90 57.51 222.39 61.86 219.73 66.07 216.88 70.18 213.86 74.17 210.68 78.03 207.34 81.76 203.86 85.33 200.22 88.77 196.45 92.05 192.54 95.17 188.51 98.13 184.36 100.91 180.10 103.52 175.73 105.95 171.26 108.20 166.70 110.26 162.06 112.12 157.34 113.78 152.57 115.25 147.73 116.51 142.84 117.57 137.92 118.42 132.96 119.07 127.99 119.51 122.98 119.74 117.98 119.77 112.98 119.59 108.01 119.21 103.04 118.62 98.11 117.83 93.21 116.82 88.36 115.62 83.56 114.21 78.82 112.59 74.17 110.79 69.59 108.79 65.10 106.60 60.70 104.22 56.39 101.65 52.22 98.92 48.15 96.01 44.20 92.93 40.40 89.70 36.72 86.30 33.19 82.76 29.82 79.08 26.59 75.25 23.53 71.31 20.64 67.23 17.91 63.03 15.37 58.74 13.00 54.33 10.83 49.83 8.83 45.23 7.04 40.57 5.45 35.84 4.06 31.03 2.87 26.18 1.88 21.27 1.10 16.33 0.53 11.37 0.16 6.37 0.00 1.39 3.61 0 8.60 0 13.60 0 18.61 0 23.61 0 28.60 0 33.61 0 38.60 0 43.61 0 48.60 0 53.49 0.12 53.68 5.11 54.25 10.07 55.19 14.99 56.50 19.81 58.17 24.52 60.19 29.09 62.56 33.50 65.25 37.71 68.25 41.71 71.54 45.47 75.10 48.97 78.92 52.20 82.97 55.14 87.22 57.76 91.66 60.05 96.26 62.00 101.00 63.60 105.85 64.83 110.78 65.69 115.75 66.17 120.75 66.29 125.74 66.03 130.70 65.40 135.59 64.40 140.41 63.03 145.09 61.30 149.64 59.21 154.00 56.80 158.20 54.05 162.15 51.00 165.88 47.66 169.34 44.06 172.52 40.20 175.39 36.13 177.96 31.84 180.20 27.36 182.09 22.73 183.63 17.97 184.80 13.12 185.59 8.18 186.02 3.19 187.89 0 192.90 0 197.88 0 202.89 0 207.89 0 212.88 0 217.89 0 222.88 0 227.88 0 232.89 0 237.90 0"
		},
		{
		  NAME: "triangle", 		
		  COLOR: BLUE_MAGENTA,
		  LEFT: 40, TOP: 16, ROT: -45,
		  WIDTH: 130, HEIGHT: 60, RADIUS: 0,
		  POINTS: "153.59 160 143.59 160 133.59 160 123.59 160 113.59 160 103.59 160 93.59 160 83.59 160 73.59 160 63.60 160 53.60 160 43.59 160 33.59 160 23.59 160 13.59 160 3.71 159.28 0 150.70 0 140.71 0 130.72 0 120.70 0 110.70 0 100.71 0 90.72 0 80.71 0 70.70 0 60.71 0 50.71 0 40.70 0 30.70 0 20.71 0 10.70 4.03 4.59 11.36 11.36 18.43 18.43 25.51 25.51 32.57 32.57 39.64 39.64 46.72 46.72 53.78 53.78 60.85 60.85 67.93 67.93 75.00 75.00 82.07 82.07 89.14 89.14 96.21 96.21 103.28 103.28 110.35 110.35 117.42 117.42 124.49 124.49 131.57 131.57 138.64 138.64 145.71 145.71 152.79 152.79 "
		},
		{
		  NAME: "ring",
		  COLOR: BLUE_MAGENTA,
		  LEFT: 50, TOP: 20, ROT: -20,
		  WIDTH: 130, HEIGHT: 130, RADIUS: +65,
		  POINTS: ""
		},		
		{ 
		  NAME: "circle", 	
		  COLOR: LIGHT_GREEN,
		  LEFT: +54, TOP: +40, ROT: -40,
		  WIDTH: +130, HEIGHT: +130, RADIUS: +65,  		
		  POINTS: ""
		},
		{
		  NAME: "semicircle", 
		  COLOR: BLUE_MAGENTA,
		  LEFT: 17, TOP: 55, ROT: 10,
		  WIDTH: 150, HEIGHT: 130, RADIUS: 0,
		  POINTS: "207.89 0.00 207.63 4.99 207.13 9.96 206.40 14.91 205.44 19.81 204.24 24.66 202.81 29.45 201.14 34.17 199.24 38.81 197.12 43.32 194.78 47.75 192.22 52.04 189.46 56.21 186.50 60.23 183.34 64.12 180.00 67.83 176.48 71.39 172.80 74.78 168.97 77.97 164.98 81.00 160.86 83.82 156.62 86.45 152.24 88.88 147.76 91.09 143.18 93.10 138.51 94.88 133.76 96.45 128.95 97.78 124.07 98.88 119.14 99.75 114.18 100.38 109.20 100.77 104.20 100.92 99.19 100.82 94.21 100.48 89.25 99.89 84.32 99.05 79.44 97.98 74.61 96.67 69.85 95.12 65.19 93.35 60.61 91.35 56.12 89.14 51.74 86.72 47.48 84.10 43.34 81.29 39.35 78.28 35.51 75.08 31.83 71.71 28.32 68.16 24.97 64.44 21.80 60.56 18.83 56.54 16.06 52.37 13.49 48.09 11.13 43.69 8.98 39.17 7.05 34.56 5.35 29.86 3.87 25.09 2.63 20.25 1.61 15.34 0.84 10.41 0.31 5.43 0.01 0.45 4.56 0 9.54 0 14.55 0 19.55 0 24.54 0 29.55 0 34.54 0 39.54 0 44.55 0 49.54 0 54.55 0 59.54 0 64.55 0 69.55 0 74.54 0 79.54 0 84.54 0 89.54 0 94.55 0 99.55 0 104.55 0 109.54 0 114.55 0 119.54 0 124.55 0 129.54 0 134.54 0 139.55 0 144.55 0 149.55 0 154.54 0 159.55 0 164.55 0 169.55 0 174.55 0 179.55 0 184.55 0 189.54 0 194.55 0 199.55 0 204.55 0"
		},		
		{
		  NAME: "rectangle",		
		  COLOR: LIGHT_GREEN,
		  LEFT: +28, TOP: +66, ROT: +0, 
		  WIDTH: +120, HEIGHT: +120, RADIUS: +0, 
		  POINTS: ""
		},
		{
		  NAME: "wave",		
		  COLOR: LIGHT_GREEN,
		  LEFT: 40, TOP: 54, ROT: 0,
		  WIDTH: 180, HEIGHT: 140, RADIUS: 0,
		  POINTS: "93.20 99.51 88.20 99.40 83.21 99.06 78.26 98.48 73.32 97.67 68.43 96.61 63.59 95.33 58.85 93.82 54.14 92.07 49.55 90.11 45.05 87.92 40.66 85.53 36.40 82.93 32.26 80.13 28.25 77.12 24.41 73.95 20.71 70.58 17.18 67.03 13.71 63.43 10.25 59.83 6.78 56.22 3.32 52.62 0.14 49.03 3.75 45.55 7.36 42.09 10.96 38.63 14.57 35.16 18.17 31.70 21.77 28.23 25.38 24.77 28.99 21.30 32.59 17.84 36.19 14.67 39.65 18.27 43.13 21.88 46.59 25.48 50.06 29.08 53.52 32.67 57.14 36.12 61.07 39.22 65.25 41.94 69.68 44.29 74.29 46.21 79.05 47.70 83.95 48.76 88.90 49.36 93.90 49.51 98.89 49.24 103.84 48.51 108.70 47.34 113.43 45.73 117.99 43.70 122.35 41.25 126.48 38.42 130.32 35.22 133.88 31.70 137.45 28.21 141.18 24.89 145.07 21.74 149.10 18.79 153.27 16.04 157.56 13.48 161.98 11.13 166.51 9.00 171.13 7.08 175.84 5.39 180.60 3.93 185.44 2.69 190.34 1.69 195.28 0.92 200.26 0.38 205.26 0.08 210.24 0.01 215.25 0.18 220.24 0.58 225.19 1.22 230.11 2.10 234.99 3.21 239.81 4.54 244.55 6.10 249.21 7.89 253.80 9.90 258.27 12.12 262.65 14.57 266.88 17.21 270.99 20.05 274.96 23.09 278.78 26.31 282.45 29.71 285.96 33.28 289.42 36.90 292.87 40.52 296.32 44.13 299.77 47.75 301.91 51.34 298.29 54.79 294.68 58.24 291.06 61.69 287.45 65.13 283.83 68.58 280.21 72.04 276.59 75.49 272.98 78.94 269.35 82.40 265.77 84.54 262.31 80.92 258.86 77.30 255.41 73.68 251.96 70.06 248.50 66.45 244.84 63.06 240.88 60.00 236.67 57.32 232.22 55.03 227.60 53.16 222.80 51.71 217.90 50.70 212.93 50.14 207.94 50.02 202.95 50.36 198.02 51.14 193.17 52.36 188.47 54.02 183.92 56.10 179.58 58.59 175.48 61.46 171.67 64.69 168.13 68.22 164.55 71.70 160.81 75.01 156.91 78.14 152.86 81.07 148.67 83.82 144.37 86.34 139.93 88.68 135.40 90.79 130.78 92.68 126.07 94.34 121.28 95.78 116.42 96.99 111.51 97.97 106.58 98.70 101.59 99.20 96.61 99.46"
		},		
		{
		  NAME: "L-polygon", 		
		  COLOR: BLUE_MAGENTA,
		  LEFT: 64, TOP: 62, ROT: -40,
		  WIDTH: 120, HEIGHT: 130, RADIUS: 0,
		  POINTS: "165.79 99.95 165.79 165.78 0 165.78 0 0 65.83 0 65.83 99.95 165.79 99.95"
		}		
];

//**********************************
//    Matter.js Objects:
//**********************************
//       aliases
var Engine = _Engine,
  Render = _Render,
  Runner = _Runner,
  Composite = _Composite,
  Composites = _Composites,
  MouseConstraint = _MouseConstraint,
  Mouse = _Mouse,
  Common = _Common,
  Query = _Query,
  World = _World,
  Bodies = _Bodies,
  Body = _Body,
  Vertices = _Vertices,
  Svg = _Svg,
  Events = _Events;  
//       instances 
var engine, world, render, runner, mouse, mouseConstraint;

//*******************************************************
// 	arrays for shapes, initial angles  
//*******************************************************
var shapes = [], shapesAngle = [];


//   shared controll vars  
var  shapesStatus, chosenShapes = [],chosenShape, isHoveringOn;
	
//  scale shapes with the screen dimensions during design (1920x1080)
const SCALE_SCREEN = (window.innerWidth + window.innerHeight) / (1920 + 1080);

	
// scale / density to harmonizing shapes 
var scaleToHarmon = 1;                    // default     
var densityToHarmon = 0.001;              //matter.js default 

//  actual viewport = actual canvas
const canvas = document.getElementById('matter-canvas');
canvas.width = document.documentElement.clientWidth;
canvas.height = document.documentElement.clientHeight;


//*********************************************
// collisions not allowed on mobile screens!!!
//if (canvas.width < TABLET_MIN_WIDTH) {
//	{ throw 'no collisions on mobile'; }
//}	
//********************************************** 


// 
//*******************************************************
//
//						Functions
//
//*******************************************************
//

//    concave decomposition support lib
Common.setDecomp(PolyDecomp);

 

//********************************************************
//    functions for initializing the scene with Matter.js
//********************************************************      
const initScene = function() {	

	// init Matter.js main components
	initMatter();
	
	//  add walls to Matter.js 
	walls = buildWalls();
	Composite.add(engine.world, walls);
	
	// add shapes to Matter.js 
	shapes = makeShapes();
	
	Composite.add(engine.world, shapes);
};	

//***************************   
const initMatter = function() {
	// create engine (shared)
	engine = Engine.create();
	
	//create world (shared)
	world = engine.world;	
	world.gravity.y = 0;
	
	// create a renderer (shared)
    render = Render.create({
        element: document.body,
		canvas: canvas,
        engine: engine,
        options: {
		    width: canvas.width,
            height: canvas.height,
			pixelRatio: "auto",
            wireframes: false,
            background: "transparent"
        }
	});
	
	Render.run(render);
	
	// add mouse controland keep in sync withj rendering
    mouse = Mouse.create(render.canvas);
	mouseConstraint = MouseConstraint.create(engine,  {	
	    mouse: mouse,
	    constraint : {
	       stiffness: 0.8,
           render: { 
			 visible: false
	       }
	    }
	});	
	
	Composite.add(world, mouseConstraint);

	// keep the mouse in sync with rendering
	render.mouse = mouse;
	
	// fit the render viewport to the scene
	Render.lookAt(render, {
	  min: { x: 0, y: 0 },
	  max: { x: canvas.width, y: canvas.height }
	});
	
	// create a runner (shared)	
	runner = Runner.create();
	Runner.run(runner, engine);
};

//***************************
//    static outer Walls 
const buildWalls = function() {
    // options for walls	
    const margin = WALL_MARGIN;
	const wallOptions = {
	  label: "wall", 	
      isStatic: true,
	  restitution: 1,
	  friction: 0,
	  frictionAir: 0,
	  frictionStatic: 0,
	  inertia: Infinity,
      collisionFilter: {
         mask: 1
      },
      render: {
        fillStyle: WHITE,
        strokeStyle: WHITE,
        lineWidth: 1.5
      }
    };

    return  [
        // Bottom wall
        Bodies.rectangle(
		  //x, y
            0, canvas.height,
		  // width, height	
            canvas.width * 2, margin,
			wallOptions
        ),
        // right wall
        Bodies.rectangle(
		  //x, y
            canvas.width, 0,
		  // width, height
            margin, canvas.height * 2,
            wallOptions
        ),
        // top wall
        Bodies.rectangle(
		  //x, y
			0, 0,
		  // width, height			
            canvas.width * 2, margin,
            wallOptions
        ),
        // left wall
        Bodies.rectangle(
		  //x, y		
            0, 0,
		  // width, height
            margin, canvas.height * 2,
            wallOptions
        ),
    ];
};

//*************************************************** 
//  all shapes - Matter.Bodies - from SVG descriptors
//***************************************************
const makeShapes = function() {
    for (let i = 0; i < SVG_SHAPES.length; i++) {
        shapes.push(makeShape(i));
    }
    return shapes;
};

//******************************
const  makeShape = function(i) { 

    // conversion to pix and radian 	
	let x = SVG_SHAPES[i].LEFT * (canvas.width / 100);
	let y = SVG_SHAPES[i].TOP * (canvas.height / 100);
	let rot =  SVG_SHAPES[i].ROT;
	if (rot < 0) {
	   rot = (360 + rot) *  (Math.PI / 360);
	}
	else if (rot > 0) {
	   rot = rot *  (Math.PI / 360); 
	}
	else { 
		rot = 0; 
	}
 	shapesAngle[i] = rot;

	let w = SVG_SHAPES[i].WIDTH;
	let h = SVG_SHAPES[i].HEIGHT;
	let r = SVG_SHAPES[i].RADIUS;
	let points = SVG_SHAPES[i].POINTS;			
	let color = SVG_SHAPES[i].COLOR;
	let name = SVG_SHAPES[i].NAME;	
	let lab = name.slice(0, 5);	
	
const shapeOptions = {
		  label: lab,
		  isStatic: false,
		  restitution: 1,
		  inertia: Infinity,
		  friction: 0,
		  frictionAir: 0,
		  frictionStatic: 0, 
		  collisionFilter: {
			 mask: 1
		  },  
	      render: {
		    fillStyle: color,
	        strokeStyle: color,
	        lineWidth: 1.5
	      }				
};
	
	//  shapes - Matter.Bodies  
	switch(name) {
	//  basic SVG to basic Matter shapes
	case "circle":
		shape = makeCircle(lab, x, y, r, color, shapeOptions); 
		break;			
	case "rectangle":  
		shape = makeRectangle(lab, x, y, rot, w, h, color, shapeOptions); 
	    break;	
			
	//  SVG ring (vertices of a hollow shape does not work in mítter.js!)
	case "ring":
		shape = makeRing(lab, x, y, r, color, shapeOptions); 
		break;	
			
	//  SVG with Points
	case "triangle":
	case "L-polygon":
	case "semiring":
	case "semicircle":	
	case "wave":			
		shape = makeShapeFromPoints(lab, points, x, y, rot, color, shapeOptions);
		break;
	default:
		return null;	
	}

	
//  scaling of shapes (size evtl mass w density) 
switch(name) {
	case "ring": 
	case "triangle":		
	    scaleToHarmon = 1.47;
		break;		
	case "rectangle": 
	case "circle":
		scaleToHarmon = 1.68;
		break;	
	case "L-polygon":
	case "semicircle":	
		scaleToHarmon = 1.26;	
		break;		
	case "semiring":
	case "wave":
		scaleToHarmon = 1.12;
		break;	
	}
	if (scaleToHarmon !== 1) {
	  Body.scale(shape, scaleToHarmon, scaleToHarmon);  
	} 
	
//  evtl scaling of shape because of screen size
	if (SCALE_SCREEN > 1.2 || SCALE_SCREEN < 0.9) {
	      Body.scale(shape, SCALE_SCREEN, SCALE_SCREEN);
	}
	
	return shape; 
};


//  SVG Points -> Vertices -> shape 	
const makeShapeFromPoints = function(lab, points, x, y, rot, color, 
		shapeOptions) 
{
	let vertexSet = [], shape;

	vertexSet = Vertices.fromPath(points, shape);
		
	shape = Bodies.fromVertices(x, y, vertexSet, shapeOptions); 
	
//  evtl rotation 
	if ( rot > 0) {
   		Body.rotate(shape, rot);
	}
	
	return shape;	
}; 

//  circle approximated with polygon 
const makeCircle = function(lab, x, y, r, color, shapeOptions) {
	let shape;
	shape = Bodies.polygon(x, y, CIRCLE_SIDES, r, shapeOptions);
	
	return shape;	
};

//   rectangle native in Matter.js
const  makeRectangle = function(lab, x, y, rot, w, h, color, shapeOptions) {
	let shape;
	shape = Bodies.rectangle(x, y, w, h, shapeOptions);
	
	return shape;	
};


 // ring by fitting rectangles with thin sides along the circumference
 const makeRing = function(lab, x, y, r, color, shapeOptions) {
	const WIDTH = 30;
	const EXTRA_LENGTH = 1.44;
	const INITIAL_ROTATION = 0;
	const ALFA = 2 * Math.PI / CIRCLE_SIDES;
	const SIDE_LENGTH = 2 * r * (ALFA / 2) * EXTRA_LENGTH;
		
	const parts = [];
	let rect, shape;
	
	for(let i = 0; i < CIRCLE_SIDES; i++) {
	 
	  rect = Bodies.rectangle(0, 0, SIDE_LENGTH, WIDTH, {
	        render: {
              fillStyle: color,
	          strokeStyle: color
			} 
	  });
	  
	  Body.rotate(rect, i * ALFA);
	  
	  Body.translate(rect, { 
	     x: r * Math.sin(i * ALFA), 
	     y: -r * Math.cos(i* ALFA)
	  });
	  
	  parts.push(rect);
	}

	shape = Body.create(shapeOptions);
	
	Body.setParts(shape, parts);	
	
	Body.setPosition(shape, {x:x, y: y});	
	
	return shape;	
};
 
//******************************************************
//	Functions to move Shapes
//*******************************************************

//   slowly at initial angle
const rouseShapes = function() {
	for (let i = 0; i < shapes.length; i++) { 
		let shape = shapes[i];
		let direction = shapesAngle[i];
		
		Body.setVelocity(shape,  {
			x: Math.sin(direction) * MIN_SPEED,
			y: Math.cos(direction) * MIN_SPEED
		});	
		Body.setAngularVelocity(shape, 0);	
	}
	shapesStatus = "roused";
};
//  dalay for rouseShapes
const delayRouseAll = function()  {
	setTimeout(rouseShapes, SLEEP_MILLISEC);
};

//  scatter shapes at random directions
const scatterShapes = function() {  
	for (let i = 0; i < shapes.length; i++) {
		let shape = shapes[i];		
		let direction = Math.random() * Math.PI * 2;
		
		Body.setVelocity(shape, {
			x: Math.sin(direction) * MAX_SPEED,
			y: Math.cos(direction) * MAX_SPEED
		});		
	}
	shapesStatus = "scattered";
};
//  delay for scatterShapes
const delayScatterAll = function()  {
	setTimeout(scatterShapes, ROUSE_MILLISEC);
};

//  regain energy because of a Matter.js bug
const rescatterShapes = function() {
    for (let i = 0; i < shapes.length; i++) {
		let shape = shapes[i];
		let sp = shape.speed;
		let av = shape.angularVelocity;

		if (shape === chosenShape) { }		
	   	
		else if (sp > 0 && sp < MIN_SPEED) { 
			let speedMultiplier = MAX_SPEED / sp;
			let vx = shape.velocity.x;
			let vy = shape.velocity.y;
		
			Body.setVelocity(shape, {
			    x: vx * speedMultiplier, 
				y: vy * speedMultiplier
			});
		}
		else if (sp == 0 || sp > MAX_SPEED) {			
			let direction = Math.random() * Math.PI * 2;
		
			Body.setVelocity(shape, {
				x: Math.sin(direction) * MAX_SPEED,
				y: Math.cos(direction) * MAX_SPEED
			});
		}	
		else if (av > MAX_ANGULAR_VELO) {
			 Body.setAngularVelocity(shape, MAX_ANGULAR_VELO);	
		}	
		else { }	
	}
};

//  revive a shape
const reviveShape = function(shape)  {
	let direction = Math.random() * Math.PI * 2;
	
	Body.setVelocity(shape, {
		x: Math.sin(direction) * MAX_SPEED,
		y: Math.cos(direction) * MAX_SPEED
	});
};

//  stop a shape 
const stopShape = function(shape) { 

	Body.setVelocity(shape, {
		x: 0, 
		y: 0	
	});
};


//
//*************************************************************
//
//  					execution  
//              
//*************************************************************
   
document.addEventListener('DOMContentLoaded', function() { 	

	// initializing controll vars
	shapesStatus = "sleeping";
	for (let i = 0; i < shapes.length; i++) { 
	   chosenShapes[i] = undefined;
	}
	chosenShape = undefined;
	isHoveringOn = false; 
		
    //  initializing matter.js and canvas 	
	initScene();
	
	// rouse slowly wout collisions
	delayRouseAll();
	
	// and after awhile scatter shapes to collide
	delayScatterAll();
	
	// MouseConstraint would capture scroll events by default 
	mouseConstraint.mouse.element.removeEventListener("mousewheel", 
	mouseConstraint.mouse.mousewheel);
	mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", 
	mouseConstraint.mouse.mousewheel);	
			
    // event: mouse down (click?) stop hoverig	
	Events.on(mouseConstraint, "mousedown", function(event1)  {
	   if (isHoveringOn) { 
	      if (shapesStatus === "scattered" && chosenShape !== undefined) {
			  
	         reviveShape(chosenShape);	 
	
			 isHoveringOn = false;
			 chosenShape = undefined; 
		   }	
       } 		  
	});

	
    // event: mouse hovering (stop & after awhile scatter)
	Events.on(mouseConstraint, "mousemove", function(event2)  {
	   if (!isHoveringOn) {	
		  chosenShapes = Query.point(shapes, event2.mouse.position);
	    
	      if (shapesStatus === "scattered" && chosenShapes[0] !== undefined) {
			 isHoveringOn = true; 
		     chosenShape = chosenShapes[0];

		     stopShape(chosenShape);
		  }
	   }		  
	});	
	
	
	// event: after awhile regain loss of energy (a Matter.js bug)
	let stamp = Date.now();
	Events.on(engine, 'beforeUpdate', function(event3) {
	   if (shapesStatus === "scattered" && 
	                        Date.now() >= stamp + REGAIN_MILLISEC) { 
	      rescatterShapes(); 
	      stamp = Date.now();
	   }
    });
	
	//  add listener to resize (scroll too?) with debounce ?
	//  if resize: ....
	//  if scroll: 
	 	
});  // end execution



